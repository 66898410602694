// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'name'
  }, {
    prop: 'short_description'
  }, {
    prop: 'hotel_id'
  }, {
    prop: 'image',
    span: 24,
    image: true
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
